@import "../../general";

.flip_upload_description_container {
  @include stc-glass-card($inputBgColor: #0e223d80);
  width: 100%;
  position: relative;
  border-radius: 12px;
  display: grid;
  padding: 2vh 3vh;
  margin: 0;
  row-gap: 20px;
  height: 25vh;
  column-gap: 40px;
  align-items: flex-start;
  grid-template-columns: 1fr 1.3fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  grid-template-areas:
    "flip_modal_logo flip_content_a flip_learn_more"
    "flip_modal_description flip_content_b flip_learn_more"
    "flip_modal_description flip_content_c flip_learn_more";
  border: 1px solid rgba(57, 77, 103, 1);
  align-items: center;

  @media screen and (min-width: 0px) and (max-width: 370px) {
    height: auto;
    column-gap: 10px;
    // min-height: 28vh;
  }

  @media screen and (min-width: 371px) and (max-width: 399px) {
    column-gap: 10px;
    // min-height: 26vh;
  }

  @include media-large-device() {
    // align-items: center;
    padding: 1.5vh;
    row-gap: 0px;
    overflow-y: hidden;
    height: 28vh;
    column-gap: 10px;
    border-radius: 8px;
    grid-template-columns: 1.3fr 1.8fr 1fr;
  }

  @include media-small-desktop() {
    height: 25vh;
    padding: 2.5vh;
    column-gap: 30px;
  }

  @include media-xl() {
    grid-template-columns: 1fr 1.6fr 1fr;
    padding: 2vh 3vh;
    column-gap: 40px;
    row-gap: 20px;
    border-radius: 12px;
  }

  @include media-tab-potrait-device() {
    @include scrollbars($size: 2px,
      $thumb-color: rgba(255, 255, 255, 0.1),
      $track-color: rgba(6, 14, 24, 1));
    min-height: auto;
    height: auto;
    width: 100%;
    overflow-y: hidden;
    padding: 1vh;
    // height: 28vh;flip_upload_desc
    row-gap: 3vh;
    column-gap: 3vh;
    grid-template-areas:
      "flip_modal_logo flip_modal_logo flip_modal_logo "
      "flip_modal_description flip_content_a  flip_content_a"
      "flip_modal_description flip_content_b flip_content_b "
      "flip_modal_description flip_content_c flip_content_c";
    align-items: center;
    justify-content: flex-start;
    padding: 3vh;
  }

  @include media-extrasmall-device() {
    min-height: 25vh;
    // height: 20vh;
    width: 100%;
    overflow-y: hidden;
    padding: 1vh;
    // height: 28vh;
    row-gap: 1vh;
    column-gap: 5vh;
    grid-template-areas:
      "flip_modal_logo flip_modal_logo flip_modal_logo "
      "flip_modal_description flip_content_a  flip_content_a"
      "flip_modal_description flip_content_b flip_content_b "
      "flip_modal_description flip_content_c flip_content_c";
    align-items: center;
    justify-content: flex-start;
  }

  @media screen and (min-width: 300px) and (max-width: 990px) and (orientation: landscape) {
    height: 28vh;
    @include scrollbars($size: 2px,
      $thumb-color: rgba(255, 255, 255, 0.1),
      $track-color: rgba(6, 14, 24, 1));
  }

  .mobile_learn_more {
    display: none;

    @include media-tab-potrait-device() {
      @include horizontalFlex();
      @include stc-btn($btnBgColor: #060e18);
      font-size: 14px;
      // @include stc-normal-font($fontColor: var(--white));
      z-index: 1;
      position: absolute;
      gap: 3vh;
      border-radius: 8px;
      top: 12px;
      right: 10px;
      padding: 1.1vh 1.5vh;
    }

    @include media-extrasmall-device() {
      @include horizontalFlex();
      @include stc-btn($btnBgColor: #060e18);
      font-size: 14px;
      // @include stc-normal-font($fontColor: var(--white));
      z-index: 1;
      position: absolute;
      gap: 3vh;
      border-radius: 8px;
      top: 7px;
      right: 6px;
      padding: 1.1vh 1.5vh;
    }

    @media screen and (min-width: 0px) and (max-width: 399px) {
      position: absolute;
      top: 7px;
      right: 6px;
      border-radius: 6px;
      padding: 0.7vh 1.3vh;
      @include stc-normal-font($fontColor: var(--white));
    }
  }
}

.mobile_play_btn {
  @include media-tab-potrait-device() {
    height: 40px;
    aspect-ratio: 1/1;
  }

  @include surface-duo() {
    height: 27px;
  }

  @include media-extrasmall-device() {
    height: 22px;
    aspect-ratio: 1/1;
  }

  @media screen and (min-width: 0px) and (max-width: 330px) {
    height: 25px;
    aspect-ratio: 1/1;
  }
}

.flip_upload_logo_container {
  @include horizontalFlex($justify: flex-start);
  @include stc-mega-heading-font($fontColor: var(--white));
  grid-area: flip_modal_logo;

  .flip_upload_back {
    display: none;

    @include media-tab-potrait-device() {
      @include verticalFlex($width: auto);
    }

    @include media-extrasmall-device() {
      @include verticalFlex($width: auto);
    }
  }

  .flip_upload_logo {
    @include horizontalFlex();

    // aspect-ratio: 1/1;
    img {
      height: 4vh;
      width: auto;
    }

    @include media-tab-potrait-device() {
      height: 30px;
    }

    @include media-extrasmall-device() {
      height: 30px;
    }

    .flip_upload_logo_img {
      background-color: transparent;
      height: 100%;
      width: 100%;
    }

    .flip_upload_name {}
  }
}

.modal_content_description {
  grid-area: flip_modal_description;
  color: #ffffff80;
  @include verticalFlex();

  @include media-large-device() {
    @include stc-normal-font($fontColor: #ffffff80);
  }

  @include media-small-desktop() {
    @include stc-normal-font($fontColor: #ffffff80);
  }

  @include media-xl() {
    // @include stc-heading-font($fontColor: #ffffff80);
  }

  @include media-tab-potrait-device() {
    @include stc-heading-font($fontColor: #ffffff80);
    min-width: 35vh;
  }

  @include media-extrasmall-device() {
    @include stc-small-font($fontColor: #ffffff80);
    min-width: 35vh;
  }

  @media screen and (min-width: 300px) and (max-width: 950px) and (orientation: landscape) {
    @include stc-normal-font($fontColor: #ffffff80);
  }
}

.modal_content_a {
  @include stc-normal-font($fontColor: var(--white));
  grid-area: flip_content_a;

  @include media-large-device() {
    @include stc-heading-font($fontColor: var(--white));
  }

  @include media-small-desktop() {
    @include stc-normal-font();
  }

  @include media-xl() {
    @include stc-normal-font($fontColor: var(--white));
  }

  @include media-tab-potrait-device() {
    min-width: 50vh;
  }

  @include media-extrasmall-device() {
    min-width: 55vh;
  }
}

.modal_content_b {
  @include stc-normal-font($fontColor: var(--white));
  grid-area: flip_content_b;

  @include media-large-device() {
    @include stc-heading-font($fontColor: var(--white));
  }

  @include media-small-desktop() {
    @include stc-normal-font();
  }

  @include media-tab-potrait-device() {
    min-width: 50vh;
  }

  @include media-extrasmall-device() {
    min-width: 55vh;
  }

  @include media-xl() {
    @include stc-normal-font($fontColor: var(--white));
  }
}

.modal_content_c {
  @include stc-normal-font($fontColor: var(--white));
  grid-area: flip_content_c;

  @include media-large-device() {
    @include stc-heading-font($fontColor: var(--white));
  }

  @include media-small-desktop() {
    @include stc-normal-font();
  }

  @include media-tab-potrait-device() {
    min-width: 50vh;
  }

  @include surface-duo() {
    min-width: 40vh;
  }

  @include media-extrasmall-device() {
    min-width: 66vh;
  }

  @include media-xl() {
    @include stc-normal-font($fontColor: var(--white));
  }
}

.modal_content_a,
.modal_content_b,
.modal_content_c {
  @media screen and (min-width: 0px) and (max-width: 399px) {
    min-width: 66vh;
  }
}

.modal_learn_more_main {
  grid-area: flip_learn_more;
  @include verticalFlex($justify: center, $gap-size: 2vh);
  @include stc-normal-font();
  height: 90%;
  width: auto;
  aspect-ratio: 3/2;
  margin-left: auto;
  border-radius: $genSmBorderRadius;
  background-color: rgb(5, 13, 24);
  cursor: pointer;

  @media screen and (min-width: 300px) and (max-width: 950px) and (orientation: landscape) {
    height: 80%;
  }

  svg {
    height: 8vh;
    width: 8vh;

    @media screen and (min-width: 300px) and (max-width: 950px) and (orientation: landscape) {
      height: 4vh;
    }
  }

  @include media-tab-landscape-device() {
    height: 70%;
    width: auto;
    // aspect-ratio: 4/3;
    margin-bottom: auto;

    svg {
      height: 6vh;
      width: 6vh;
    }
  }

  // @include media-large-device() {
  //   width: 200px;
  //   @include stc-heading-font($fontColor: var(--white));
  // }
  // @include media-small-desktop() {
  //   width: 250px;
  // }
  // @include media-xl() {
  //   @include stc-normal-font();
  //   width: 100%;
  // }
  @include media-tab-potrait-device() {
    display: none;
  }

  @include media-extrasmall-device() {
    display: none;
  }
}

.text_strong {
  @include stc-heading-font($fontColor: var(--white));

  @include media-large-device() {
    @include stc-mega-heading-font($fontColor: var(--white));
  }

  @include media-small-desktop() {
    @include stc-heading-font();
  }

  @include media-xl() {}
}

#iframe {
  height: 190px;
  border-radius: 8px;
  @include horizontalFlex();
  background: #3d3d3d;

  @include media-large-device() {
    height: 150px;
  }

  @include media-xl() {
    height: 185px;
  }
}

.yt_image_icon {
  cursor: pointer;
}

.yt_icon_center {
  position: absolute;
  top: 42%;
  cursor: pointer;
  left: 50%;
  transform: translate(-50%, -50%);
}

.mobile_learn_logo {
  @include horizontalFlex($justify: center, $width: auto);
}

.mobile_learn_text {
  color: var(--white);

  @include media-tab-potrait-device() {
    @include stc-heading-font();
  }
}

.watch_video_text {
  // @include stc-normal-font($fontColor: var(--white));
  @include stc-sub-heading-font($fontColor: var(--white));
  // position: absolute;
  // bottom: 1vh;

  // @include media-large-device() {
  //   @include stc-normal-font($fontColor: var(--white));
  // }
  // @include media-xl() {
  //   @include stc-normal-font($fontColor: var(--white));
  // }
}